import { useState, useEffect, useContext, useCallback, useMemo } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import moment from 'moment';
import WhitePanelContentWrapper from '../../../components/white-panel-content-wrapper';
import PanelTitle from '../../../components/panel-title';
import CustomSelect from '../../../components/customSelect';
import HoursPageTimesheetToolbarDatePicker from '../timesheet/toolbar/DatePicker';
import ButtonSecondary from '../../../components/button/ButtonSecondary';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import showNotification from '../../../helpers/showNotification';
import { ProjectsContext } from '../../../context/ProjectsContext';
import {
    getAllHours,
    getInferredHours,
    getJiraProjects,
    getProjects,
} from '../../../api/hours/HoursAPI';
import HoursReportTable from '../../../components/table-hours-report';
import { useFetchDataAndSetState } from '../../../helpers/useFetchDataAndSetState';
import {
    getTableDataForExport,
    makeCsv,
} from '../../../helpers/csv-table-export';
import { exportPDF } from '../../../helpers/pdf-table-export';
import { useTranslation } from 'react-i18next';
import SettingsMessage from '../../../components/settings-message';
import { NAVIGATION_PATHS } from '../../../constants/navigationPaths';
import { fetchUsersAndInsertPhotos } from '../../../helpers/fetchUserPhotos';

export default function ReportTab() {
    const { t } = useTranslation();

    const { organisationsState } = useContext(OrganisationsContext);
    const selectedOrgHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;

    const { projectsState, projectsDispatch } = useContext(ProjectsContext);

    const [filterParams, setFilterParams] = useState({
        filterDate: moment().startOf('month').format('YYYY-MM-DD'),
        interval: moment().daysInMonth(),
        employees: [],
        projects: [],
        jiraProjects: [],
    });

    const [filteredRegisteredData, setFilteredRegisteredData] = useState([]);
    const [filteredInferredData, setFilteredInferredData] = useState([]);

    const [allEmployees, setAllEmployees] = useState([]);
    const [allInferredEmployees, setAllInferredEmployees] = useState([]);
    const [allProjects, setAllProjects] = useState([]);
    const [allJiraProjects, setAllJiraProjects] = useState([]);
    const [reportType, setReportType] = useState({
        value: 'registered',
        label: 'Registered hours',
    });

    const registeredHoursRequest = useCallback(async () => {
        let response = { data: null, status: 200 };

        if (allEmployees?.length) {
            const res = await getAllHours({
                params: {
                    filterDate: filterParams.filterDate,
                    interval: moment(filterParams.filterDate).daysInMonth(),
                },
            });

            if (res?.data.length) {
                let data = res?.data.map((item) => {
                    return {
                        ...item,
                        employee: allEmployees?.find(
                            (imp) => item?.user?.hash === imp.userHash
                        ),
                    };
                });
                const formattedData = data
                    .reduce((acc, item) => {
                        let projectsArray = item.projects_data.reduce(
                            (acc, projectData) => {
                                let projectHours = projectData.days.map(
                                    (day) => {
                                        return {
                                            date: moment(day.date).format(
                                                'YYYY-MM-DD (dd)'
                                            ),
                                            employee: item?.employee?.label,
                                            project: projectData.project,
                                            description: allProjects.find(
                                                (project) =>
                                                    project.value ===
                                                    projectData.project
                                            )?.description,
                                            hours: day.hours,
                                            week: moment(day.date).week(),
                                            userHash: item?.user?.hash,
                                        };
                                    }
                                );
                                return [...acc, ...projectHours];
                            },
                            []
                        );

                        return [...acc, ...projectsArray];
                    }, [])
                    .sort((a, b) => new Date(a.date) - new Date(b.date));

                response = {
                    ...response,
                    data: formattedData,
                    status: 200,
                };
            }
        } else {
            response = { data: [], status: 204 };
        }

        return response;
    }, [filterParams.filterDate, allEmployees]);

    const inferredHoursRequest = useCallback(async () => {
        const res = await getInferredHours({
            params: {
                date_start: moment(filterParams.filterDate).unix(),
                date_end: moment(filterParams.filterDate)
                    .add(filterParams?.interval, 'days')
                    .subtract(1, 'seconds')
                    .unix(),
            },
        });

        if (res.data.length) {
            const formattedData = res.data.map((row) => {
                return {
                    date: moment.unix(row.timestamp).format('YYYY-MM-DD (dd)'),
                    employee: row.user.user,
                    project: row.project.name,
                    projectKey: row.project.key,
                    tickets: row.tickets.reduce(
                        (acc, ticket, i) =>
                            i === row?.tickets?.length - 1
                                ? acc + ticket
                                : acc + ticket + ', ',
                        ''
                    ),
                    hours: row.hours,
                    week: moment.unix(row.timestamp).week(),
                    userHash: row.user.hash,
                };
            });

            const formattedRes = { ...res, data: formattedData };

            return formattedRes;
        } else {
            return res;
        }
    }, [filterParams.filterDate, allEmployees]);

    const [allRegisteredHoursDataState] = useFetchDataAndSetState(
        registeredHoursRequest,
        [filterParams.filterDate]
    );

    const { data, loading, error } = allRegisteredHoursDataState;

    const [allInferredHoursDataState] = useFetchDataAndSetState(
        inferredHoursRequest,
        [filterParams.filterDate]
    );

    const registerdTableHeaders = [
        {
            Header: () => <>{t('hours_page.hours_report.date')}</>,
            className:
                'col-span-2 font-medium leading-6 py-2 pr-1 border-solid border-b-2 border-gray-300 text-left',
            accessor: 'date',
            csv: t('hours_page.hours_report.date'),
        },
        {
            Header: () => <>{t('hours_page.hours_report.employee')}</>,
            className:
                'col-span-2 font-medium leading-6 py-2 pr-1 border-solid border-b-2 border-gray-300 text-left',
            accessor: 'employee',
            csv: t('hours_page.hours_report.employee'),
        },
        {
            Header: () => <>{t('hours_page.hours_report.project')}</>,
            accessor: 'project',
            className:
                'col-span-3 font-medium leading-6 py-2 pr-1 border-solid border-b-2 border-gray-300 text-left',
            csv: t('hours_page.hours_report.project'),
        },
        {
            Header: () => <>{t('hours_page.hours_report.description')}</>,
            accessor: 'description',
            className:
                'col-span-4 font-medium leading-6 py-2 pr-1 border-solid border-b-2 border-gray-300 text-left',
            Footer: () => {
                return (
                    <div className="w-full text-right font-medium leading-6 pl-auto pr-8">
                        Total:
                    </div>
                );
            },
            csv: t('hours_page.hours_report.description'),
        },
        {
            Header: () => <>{t('hours_page.hours_report.hours')}</>,
            accessor: 'hours',
            className:
                'col-span-1 font-medium leading-6 py-2 border-solid border-b-2 border-gray-300 text-left',
            Footer: (info) => {
                // Only calculate total visits if rows change
                const total = useMemo(
                    () =>
                        info.rows.reduce(
                            (sum, row) => row.values.hours + sum,
                            0
                        ),
                    [info.rows]
                );

                return <>{total.toFixed(2)}</>;
            },
            csv: t('hours_page.hours_report.hours'),
        },
    ];

    const inferredTableHeaders = [
        {
            Header: () => <>{t('hours_page.hours_report.date')}</>,
            className:
                'col-span-2 font-medium leading-6 py-2 pr-1 border-solid border-b-2 border-gray-300 text-left',
            accessor: 'date',
            csv: t('hours_page.hours_report.date'),
        },
        {
            Header: () => <>{t('hours_page.hours_report.employee')}</>,
            className:
                'col-span-2 font-medium leading-6 py-2 pr-1 border-solid border-b-2 border-gray-300 text-left',
            accessor: 'employee',
            csv: t('hours_page.hours_report.employee'),
        },
        {
            Header: () => <>{t('hours_page.hours_report.project')}</>,
            accessor: 'project',
            className:
                'col-span-2 font-medium leading-6 py-2 pr-1 border-solid border-b-2 border-gray-300 text-left',
            csv: t('hours_page.hours_report.project'),
        },
        {
            Header: () => <>{t('hours_page.hours_report.tickets')}</>,
            accessor: 'tickets',
            className:
                'col-span-5 font-medium leading-6 py-2 pr-1 border-solid border-b-2 border-gray-300 text-left  ',
            Footer: () => {
                return (
                    <div className="w-full text-right font-medium leading-6 pl-auto pr-8">
                        Total:
                    </div>
                );
            },
            csv: t('hours_page.hours_report.tickets'),
        },
        {
            Header: () => <>{t('hours_page.hours_report.hours')}</>,
            accessor: 'hours',
            className:
                'col-span-1 font-medium leading-6 py-2 border-solid border-b-2 border-gray-300 text-left',
            Footer: (info) => {
                // Only calculate total visits if rows change
                const total = useMemo(
                    () =>
                        info.rows.reduce(
                            (sum, row) => row.values.hours + sum,
                            0
                        ),
                    [info.rows]
                );

                return <>{total.toFixed(2)}</>;
            },
            csv: t('hours_page.hours_report.hours'),
        },
    ];

    useEffect(() => {
        async function getUsers() {
            try {
                const res = await fetchUsersAndInsertPhotos(false);
                if (res.status !== 204) {
                    const options = res.data.map((user) => {
                        let label = `${user.first_name} ${user.last_name}`;
                        if (!user.first_name || !user.last_name) {
                            label = user.email;
                        }
                        return {
                            value: user.hash,
                            label: label,
                            userHash: user.hash,
                            isSelected: true,
                            jiraStatus:
                                user?.user_profiles?.length &&
                                user.user_profiles[0]?.jira_hash
                                    ? true
                                    : false,
                        };
                    });
                    setAllEmployees(options);

                    setAllInferredEmployees(
                        options.map((option) => {
                            return {
                                ...option,
                                isDisabled: option.jiraStatus ? false : true,
                                isSelected: option.jiraStatus ? true : false,
                            };
                        })
                    );
                } else {
                    setAllEmployees([]);
                    setAllInferredEmployees([]);
                }
            } catch (err) {
                showNotification(`${err.response?.data || err.message}`);
            }
        }

        async function fetchProjects() {
            projectsDispatch({ type: 'SET_LOADING' });
            try {
                const res = await getProjects();
                if (res.status === 204) {
                    projectsDispatch({ type: 'SET_DATA', payload: [] });
                } else {
                    projectsDispatch({ type: 'SET_DATA', payload: res.data });
                }
            } catch (err) {
                projectsDispatch({ type: 'SET_ERROR', payload: err });
            }
        }

        async function fetchJiraProjects() {
            try {
                const res = await getJiraProjects();
                if (res.status !== 204) {
                    const formattedData = res.data.reduce((acc, project) => {
                        const formattedProject = {
                            value: project.key,
                            label: project.name,
                            isSelected: true,
                        };
                        return [...acc, formattedProject];
                    }, []);
                    setAllJiraProjects(formattedData);
                } else {
                    setAllJiraProjects([]);
                }
            } catch (err) {
                showNotification(`${err.response?.data || err.message}`);
            }
        }

        void getUsers();
        void fetchProjects();
        void fetchJiraProjects();
    }, [selectedOrgHash]);

    useEffect(() => {
        handleFetchAllProjects();
    }, [projectsState.data]);

    useEffect(() => {
        setFilterParams({
            ...filterParams,
            projects: allProjects.filter((project) => project.isSelected),
        });
    }, [allProjects]);

    useEffect(() => {
        setFilterParams({
            ...filterParams,
            jiraProjects: allJiraProjects.filter(
                (project) => project.isSelected
            ),
        });
    }, [allJiraProjects]);

    useEffect(() => {
        setFilterParams({
            ...filterParams,
            employees: allEmployees.filter((employee) => employee.isSelected),
            inferredEmployees: allInferredEmployees.filter(
                (employee) => employee.isSelected
            ),
        });
    }, [allEmployees, allInferredEmployees]);

    useEffect(() => {
        if (data && reportType.value === 'registered') {
            const filteredRegisteredData = data.filter(
                (dataItem) =>
                    filterParams.employees.find(
                        (employee) => employee.userHash === dataItem.userHash
                    ) &&
                    filterParams.projects.find(
                        (project) => project.value === dataItem.project
                    )
            );

            setFilteredRegisteredData(filteredRegisteredData);
        }

        if (
            allInferredHoursDataState?.data?.length &&
            reportType.value === 'inferred'
        ) {
            const filteredInferredData = allInferredHoursDataState.data.filter(
                (dataItem) =>
                    filterParams.inferredEmployees.find(
                        (employee) => employee.userHash === dataItem.userHash
                    ) &&
                    filterParams.jiraProjects.find(
                        (project) => project.value === dataItem.projectKey
                    )
            );

            setFilteredInferredData(filteredInferredData);
        }
    }, [filterParams, data, allInferredHoursDataState, reportType]);

    const startOfCurrentMonthDate = moment().startOf('month');

    const exportOptions = [
        { value: 'csv', label: 'CSV' },
        { value: 'pdf', label: 'PDF' },
    ];

    const reportTypeOptions = [
        { value: 'registered', label: 'Registered hours' },
        { value: 'inferred', label: 'Inferred hours' },
    ];

    function handleFetchAllProjects() {
        projectsState?.data?.length &&
            setAllProjects(
                projectsState.data.map((project) => {
                    return {
                        value: project.name,
                        label: project.name,
                        description: project.description,
                        isSelected: true,
                    };
                })
            );
    }

    function handleSetFilterDate(newDate) {
        setFilterParams({
            ...filterParams,
            filterDate: moment(newDate).startOf('month').format('YYYY-MM-DD'),
        });
    }

    function subtractMonth() {
        const newDate = moment(filterParams.filterDate)
            .clone()
            .subtract(1, 'month')
            .format('YYYY-MM-DD');
        handleSetFilterDate(newDate);
    }

    function addMonth() {
        const newDate = moment(filterParams.filterDate)
            .clone()
            .add(1, 'month')
            .format('YYYY-MM-DD');
        handleSetFilterDate(newDate);
    }

    function setFilterDateNow() {
        handleSetFilterDate(moment().startOf('month').format('YYYY-MM-DD'));
    }

    async function handleExportOptionChange(value) {
        if (!value) {
            return;
        }
        const filename = 'hours_report';

        if (value.value === 'csv') {
            if (reportType.value === 'registered') {
                const registeredDataForCsv = prepareDataForExport(
                    filteredRegisteredData
                );
                try {
                    await makeCsv(
                        getTableDataForExport(
                            registeredDataForCsv,
                            registerdTableHeaders,
                            'csv'
                        ),
                        `${filename}.csv`
                    );
                } catch (error) {
                    console.log(error);
                }
            } else {
                const inferredDataForCsv =
                    prepareDataForExport(filteredInferredData);
                try {
                    await makeCsv(
                        getTableDataForExport(
                            inferredDataForCsv,
                            inferredTableHeaders,
                            'csv'
                        ),
                        `${filename}.csv`
                    );
                } catch (error) {
                    console.log(error);
                }
            }
        } else if (value.value === 'pdf') {
            if (reportType.value === 'registered') {
                const registeredDataForCsv = prepareDataForExport(
                    filteredRegisteredData
                );
                const title = moment(filterParams.filterDate).format(
                    'MMMM YYYY'
                );
                exportPDF(title, registerdTableHeaders, registeredDataForCsv);
            } else {
                const inferredDataForCsv =
                    prepareDataForExport(filteredInferredData);
                const title = moment(filterParams.filterDate).format(
                    'MMMM YYYY'
                );
                exportPDF(title, inferredTableHeaders, inferredDataForCsv);
            }
        }
    }

    function handleReportTypeChange(value) {
        setReportType(value);
    }

    function handleEmployeesChange(value) {
        let formattedEmployees = allEmployees;
        let formattedInferredEmployees = allInferredEmployees;
        if (Array.isArray(value)) {
            formattedEmployees = allEmployees.map((employee) => {
                if (value.find((option) => option.value === employee.value)) {
                    return {
                        ...employee,
                        isSelected: true,
                    };
                } else
                    return {
                        ...employee,
                        isSelected: false,
                    };
            });
        } else if (value) {
            formattedEmployees = allEmployees.map((employee) => {
                if (value.value === employee.value) {
                    return {
                        ...employee,
                        isSelected: true,
                    };
                } else
                    return {
                        ...employee,
                        isSelected: false,
                    };
            });
        } else {
            formattedEmployees = allEmployees.map((employee) => {
                return {
                    ...employee,
                    isSelected: false,
                };
            });
        }

        if (Array.isArray(value)) {
            formattedInferredEmployees = allInferredEmployees.map(
                (employee) => {
                    if (
                        value.find(
                            (option) =>
                                option.value === employee.value &&
                                employee.jiraStatus
                        )
                    ) {
                        return {
                            ...employee,
                            isSelected: true,
                        };
                    } else
                        return {
                            ...employee,
                            isSelected: false,
                        };
                }
            );
        } else if (value) {
            formattedInferredEmployees = allInferredEmployees.map(
                (employee) => {
                    if (value.value === employee.value) {
                        return {
                            ...employee,
                            isSelected: true,
                        };
                    } else
                        return {
                            ...employee,
                            isSelected: false,
                        };
                }
            );
        } else {
            formattedInferredEmployees = allInferredEmployees.map(
                (employee) => {
                    return {
                        ...employee,
                        isSelected: false,
                    };
                }
            );
        }

        setAllEmployees(formattedEmployees);
        setAllInferredEmployees(formattedInferredEmployees);
    }

    function handleProjectsChange(value) {
        let formattedProjects = allProjects;
        if (Array.isArray(value)) {
            formattedProjects = allProjects.map((project) => {
                if (value.find((option) => option.value === project.value)) {
                    return {
                        ...project,
                        isSelected: true,
                    };
                } else
                    return {
                        ...project,
                        isSelected: false,
                    };
            });
        } else if (value) {
            formattedProjects = allProjects.map((project) => {
                if (value.value === project.value) {
                    return {
                        ...project,
                        isSelected: true,
                    };
                } else
                    return {
                        ...project,
                        isSelected: false,
                    };
            });
        } else {
            formattedProjects = allProjects.map((project) => {
                return {
                    ...project,
                    isSelected: false,
                };
            });
        }

        setAllProjects(formattedProjects);
    }

    function handleJiraProjectsChange(value) {
        let formattedJiraProjects = allJiraProjects;
        if (Array.isArray(value)) {
            formattedJiraProjects = allJiraProjects.map((project) => {
                if (value.find((option) => option.value === project.value)) {
                    return {
                        ...project,
                        isSelected: true,
                    };
                } else
                    return {
                        ...project,
                        isSelected: false,
                    };
            });
        } else if (value) {
            formattedJiraProjects = allJiraProjects.map((project) => {
                if (value.value === project.value) {
                    return {
                        ...project,
                        isSelected: true,
                    };
                } else
                    return {
                        ...project,
                        isSelected: false,
                    };
            });
        } else {
            formattedJiraProjects = allJiraProjects.map((project) => {
                return {
                    ...project,
                    isSelected: false,
                };
            });
        }

        setAllJiraProjects(formattedJiraProjects);
    }

    function prepareDataForExport(tableData) {
        if (!tableData) {
            showNotification('No data');
            return;
        }
        const withTotals = tableData.reduce((acc, row, i, allData) => {
            if (allData[i]?.week !== allData[i + 1]?.week) {
                let weekStartDay = moment()
                    .day(1)
                    .week(allData[i].week)
                    .format('D');

                let weekEndDay = moment()
                    .day(0)
                    .week(allData[i].week + 1)
                    .format('D');

                if (+weekStartDay > +weekEndDay) {
                    weekStartDay = '1';
                }

                weekEndDay = moment()
                    .day(0)
                    .week(allData[i].week + 1)
                    .format('D MMMM');

                const weekHours = allData
                    .filter((date) => date.week === allData[i]?.week)
                    .reduce((acc, date) => {
                        return acc + date.hours;
                    }, 0);
                const weekTitle = `Week ${allData[i].week} (${weekStartDay}-${weekEndDay})`;

                const weekTotalRow = {
                    date: '',
                    employee: '',
                    project: '',
                    description: weekTitle,
                    hours: weekHours,
                };

                if (i === allData.length - 1) {
                    let total = allData.reduce(
                        (sum, row) => row.hours + sum,
                        0
                    );

                    const totalRow = {
                        date: '',
                        employee: '',
                        project: '',
                        description: 'Total',
                        hours: total,
                    };

                    return [...acc, row, weekTotalRow, totalRow];
                }

                return [...acc, row, weekTotalRow];
            }

            return [...acc, row];
        }, []);

        return withTotals;
    }

    return (
        <>
            {reportType.value === 'inferred' &&
            allInferredEmployees.find((employee) => !employee.jiraStatus) ? (
                <div className="mt-2 w-full lg:w-9/12">
                    <SettingsMessage
                        messageText="Seems like some users don't have their Jira profile connected. Establish connection in Users settings, so we can estimate their inferred hours."
                        link={NAVIGATION_PATHS.settingsUsers}
                        linkText="Go to Users settings"
                    />
                </div>
            ) : null}
            <WhitePanelContentWrapper className="w-full lg:w-9/12  relative">
                <div className="flex justify-between items-center">
                    <div className="flex gap-x-2">
                        <PanelTitle
                            title={t('hours_page.hours_report.title')}
                            underlineColorType="tertiary"
                        />
                        <CustomSelect
                            options={reportTypeOptions}
                            defaultOption={reportType}
                            selectedValues={reportType}
                            onChange={handleReportTypeChange}
                            styleType="smallBlue"
                            width="140px"
                            height="24px"
                        />
                    </div>
                    <h4 className="font-semibold text-2xl leading-12 ">
                        {moment(filterParams.filterDate).format('MMMM YYYY') ??
                            startOfCurrentMonthDate.format('MMMM YYYY')}
                    </h4>
                    <CustomSelect
                        options={exportOptions}
                        onChange={handleExportOptionChange}
                        styleType="lightBlue"
                        placeholder="Export"
                        isClearable={true}
                        width="140px"
                    />
                </div>
                <div className="flex justify-between items-center mt-4">
                    <div id="filters" className="flex gap-x-4">
                        {reportType.value === 'registered' ? (
                            <CustomSelect
                                options={allEmployees}
                                defaultOption={allEmployees}
                                selectedValues={filterParams.employees}
                                onChange={handleEmployeesChange}
                                styleType="lightBlue"
                                placeholder="Employees"
                                selectAllLabel="All employees"
                                isMulti={true}
                                width="200px"
                                heigth="38px"
                            />
                        ) : (
                            <CustomSelect
                                options={allInferredEmployees}
                                defaultOption={allInferredEmployees}
                                selectedValues={filterParams.inferredEmployees}
                                onChange={handleEmployeesChange}
                                styleType="lightBlue"
                                placeholder="Employees"
                                selectAllLabel="All employees"
                                isMulti={true}
                                width="200px"
                                heigth="38px"
                            />
                        )}
                        {reportType.value === 'registered' ? (
                            <CustomSelect
                                options={allProjects}
                                defaultOption={allProjects}
                                selectedValues={filterParams.projects}
                                onChange={handleProjectsChange}
                                styleType="lightBlue"
                                placeholder="Projects"
                                selectAllLabel="All projects"
                                isMulti={true}
                                width="200px"
                                heigth="38px"
                            />
                        ) : (
                            <CustomSelect
                                options={allJiraProjects}
                                defaultOption={allJiraProjects}
                                selectedValues={filterParams.jiraProjects}
                                onChange={handleJiraProjectsChange}
                                styleType="lightBlue"
                                placeholder="Projects"
                                selectAllLabel="All Jira projects"
                                isMulti={true}
                                width="200px"
                                heigth="38px"
                            />
                        )}
                    </div>
                    <div className="flex justify-between items-center space-x-4">
                        <HoursPageTimesheetToolbarDatePicker
                            filterDate={moment(filterParams.filterDate)}
                            setFilterDate={handleSetFilterDate}
                            updateDates={() => {}} //TODO: counter for triggering table update
                        />
                        <div className="flex justify-between items-center ">
                            <ButtonSecondary
                                variant={'gray'}
                                extraClasses={'h-8'}
                                onClick={subtractMonth}
                            >
                                <IoIosArrowBack />
                            </ButtonSecondary>
                            <ButtonSecondary
                                variant={'gray'}
                                extraClasses={'h-8'}
                                onClick={setFilterDateNow}
                            >
                                This month
                            </ButtonSecondary>
                            <ButtonSecondary
                                variant={'gray'}
                                extraClasses={'h-8'}
                                onClick={addMonth}
                            >
                                <IoIosArrowForward />
                            </ButtonSecondary>
                        </div>
                    </div>
                </div>
                {reportType.value === 'inferred' ? (
                    selectedOrgHash === demoOrgHash ? (
                        <div className="mt-8">
                            <HoursReportTable
                                data={filteredRegisteredData}
                                headers={registerdTableHeaders}
                                error={error}
                                loading={loading}
                            />
                        </div>
                    ) : (
                        <div className="mt-8">
                            <HoursReportTable
                                data={filteredInferredData}
                                headers={inferredTableHeaders}
                                error={allInferredHoursDataState.error}
                                loading={allInferredHoursDataState.loading}
                            />
                        </div>
                    )
                ) : null}
                {reportType.value === 'registered' ? (
                    <div className="mt-8">
                        <HoursReportTable
                            data={filteredRegisteredData}
                            headers={registerdTableHeaders}
                            error={error}
                            loading={loading}
                        />
                    </div>
                ) : null}
            </WhitePanelContentWrapper>
        </>
    );
}
