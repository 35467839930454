import React, { useContext, useState, useCallback, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { UserContext } from '../../../context/UserContext';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import WorkspaceItem from '../../workspace-item';
import AddWorkspaceItem from '../../add-workspace-item';
import Loader from '../../loader';

import { useFetchDataAndSetState } from '../../../helpers/useFetchDataAndSetState';
import {
    deleteJiraWorkspace,
    getJiraWorkspaces,
} from '../../../api/settings/sprintInsightsAPI';
import showNotification from '../../../helpers/showNotification';
import { getAdminPermission } from '../../../helpers/getPermission';
import { fetchUsersAndInsertPhotos } from '../../../helpers/fetchUserPhotos';

const SprintInsightsTab = () => {
    const { userState } = useContext(UserContext);
    const { organisationsState } = useContext(OrganisationsContext);
    const userHash = userState.data.user_hash;
    const selectedOrgHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;
    const request = useCallback(() => getJiraWorkspaces(), []);
    const [updatesCounter, setUpdatesCounter] = useState(0);
    const [workspacesState] = useFetchDataAndSetState(request, [
        selectedOrgHash,
        updatesCounter,
    ]);
    const [newWorkspaces, setNewWorkspaces] = useState([]);

    const usersRequest = useCallback(
        () => fetchUsersAndInsertPhotos(false),
        []
    );
    const [usersState] = useFetchDataAndSetState(usersRequest, [
        selectedOrgHash,
    ]);
    const [isInOrg, setIsInOrg] = useState(true);
    const [permissionForWrite, setPermissionForWrite] = useState(false);

    useEffect(() => {
        !usersState.loading &&
        usersState?.data?.find(
            (user) => user.hash.toLowerCase() === userHash.toLowerCase()
        )
            ? setIsInOrg(true)
            : setIsInOrg(false);
    }, [userHash, usersState]);

    useEffect(() => {
        setNewWorkspaces([]);
    }, [selectedOrgHash]);

    useEffect(() => {
        setPermissionForWrite(getAdminPermission(organisationsState?.data));
    }, [organisationsState]);

    const addWorkspace = (service) => {
        const newWorkspace = {
            name: uuidv4(),
            source: service,
            webhook: false,
        };
        setNewWorkspaces([...newWorkspaces, newWorkspace]);
    };

    const deleteWorkspace = async (service, workspaceName, userName) => {
        if (!userName) {
            const updatedNewWorkspaces = newWorkspaces.filter(
                (workspace) => workspace.name !== workspaceName
            );
            setNewWorkspaces(updatedNewWorkspaces);
            return;
        }
        if (service === 'jira') {
            try {
                const res = await deleteJiraWorkspace(workspaceName, userName);
                res && setUpdatesCounter();
                showNotification('Successfully deleted', 'success');
            } catch (error) {
                showNotification(error.response?.data || error.message);
            }
        } else {
            console.log('deleting from azure');
        }
        try {
            // TODO: replace with AOI for Azure DevOps
            // const res = await deleteAzureWorkspace(workspaceName, userHash);
            // res && setWorkspaces();
        } catch (error) {
            showNotification(error.response?.data || error.message);
        }
    };

    if (workspacesState.loading) {
        return (
            <div className="pb-8 " id="sprint-insights">
                <div className="flex justify-center items-center mt-4">
                    <Loader color={'#C2C7D7'} size={35} speedMultiplier={0.8} />
                </div>
            </div>
        );
    }

    return (
        <div className="pb-8 " id="sprint-insights">
            <ul className="flex flex-wrap gap-x-8 gap-y-10">
                {Array.isArray(workspacesState.data) &&
                    workspacesState.data.map((workspace) => {
                        return (
                            <WorkspaceItem
                                workspace={workspace}
                                key={
                                    workspace.name +
                                    workspace.modified_user.hash
                                }
                                onDelete={deleteWorkspace}
                                isInOrg={isInOrg}
                                loading={usersState.loading}
                                permissionForWrite={permissionForWrite}
                            />
                        );
                    })}
                {newWorkspaces.length > 0 &&
                    newWorkspaces.map((workspace) => {
                        return (
                            <WorkspaceItem
                                workspace={workspace}
                                key={workspace.name}
                                onDelete={deleteWorkspace}
                                permissionForWrite={permissionForWrite}
                            />
                        );
                    })}
                {permissionForWrite ? (
                    +newWorkspaces?.length + +workspacesState?.data?.length >=
                    5 ? (
                        <li className="w-480 h-300 rounded-lg border-tailwind border-gray-500 border-dashed flex flex-col justify-center items-center ">
                            <p className="p-4 font-display text-xl text-center text-gray-500">
                                Maximum 5 Ticket Feeds could be added
                            </p>
                        </li>
                    ) : (
                        <AddWorkspaceItem
                            addWorkspace={addWorkspace}
                            isInOrg={isInOrg}
                            loading={usersState.loading}
                        />
                    )
                ) : null}
            </ul>
        </div>
    );
};

export default SprintInsightsTab;
