import { getOrgUsers } from '../api/settings/UserAPI';
import { getTasks, getTeamTasks } from '../api/si/si';
import { getUserPic } from '../api/user/UserAPI';

export async function addUserPhotoToTheUserData(userData) {
    let dataWithPhoto = userData;

    if (!userData?.photo || userData?.photo?.includes('None')) {
        dataWithPhoto = {
            ...userData,
            photo: null,
        };
    } else {
        try {
            const res = await getUserPic(userData?.photo);
            if (res?.data) {
                dataWithPhoto = {
                    ...userData,
                    photo: URL.createObjectURL(res.data),
                };
            }
        } catch (err) {
            dataWithPhoto = {
                ...userData,
                photo: null,
            };
        }
    }
    return dataWithPhoto;
}

export async function fetchUsersAndInsertPhotos(fetchPics = true) {
    let response = await getOrgUsers();

    if (response?.data?.length && fetchPics) {
        const insertedPhotoPromises = response?.data?.map(async (user) => {
            const updated = await addUserPhotoToTheUserData(user);
            return updated;
        });
        const withInsertedPhoto = await Promise.all(insertedPhotoPromises);
        response = { ...response, data: withInsertedPhoto };
    }

    return response;
}

export async function addUserPhotoToTheTicketData(ticketData) {
    let dataWithPhoto = ticketData;

    if (
        !ticketData?.assignee_photo ||
        ticketData?.assignee_photo?.includes('None')
    ) {
        dataWithPhoto = {
            ...ticketData,
            assignee_photo: null,
        };
    } else {
        try {
            const res = await getUserPic(ticketData?.assignee_photo);
            if (res?.data) {
                dataWithPhoto = {
                    ...ticketData,
                    assignee_photo: URL.createObjectURL(res.data),
                };
            }
        } catch (err) {
            dataWithPhoto = {
                ...ticketData,
                assignee_photo: null,
            };
        }
    }
    return dataWithPhoto;
}

export async function getTicketsAndIncertPhotos(
    startDate,
    endDate,
    offset,
    paramsObj,
    limit = 50
) {
    let response = await getTasks(startDate, endDate, offset, paramsObj, limit);

    if (response?.data?.tickets?.length) {
        const insertedPhotoPromises = response?.data?.tickets?.map(
            async (ticket) => {
                const updated = await addUserPhotoToTheTicketData(ticket);
                return updated;
            }
        );
        const withInsertedPhoto = await Promise.all(insertedPhotoPromises);
        response = {
            ...response,
            data: { ...response?.data, tickets: withInsertedPhoto },
        };
    }

    return response;
}

export async function getTeamTicketsAndIncertPhotos(
    teamHash,
    startDate,
    endDate,
    offset,
    paramsObj,
    limit = 50
) {
    let response = await getTeamTasks(
        teamHash,
        startDate,
        endDate,
        offset,
        paramsObj,
        limit
    );

    if (response?.data?.tickets?.length) {
        const insertedPhotoPromises = response?.data?.tickets?.map(
            async (ticket) => {
                const updated = await addUserPhotoToTheTicketData(ticket);
                return updated;
            }
        );
        const withInsertedPhoto = await Promise.all(insertedPhotoPromises);
        response = {
            ...response,
            data: { ...response?.data, tickets: withInsertedPhoto },
        };
    }

    return response;
}
