import React, { useEffect } from 'react';
import {
    Routes,
    Route,
    Navigate,
    useNavigate,
    useLocation,
} from 'react-router-dom';
import { Content } from './content';
import { SetPasswordPage } from '../../pages';
import { OrganisationsProvider } from '../../context/OrganisationsContext';
import { UserProvider } from '../../context/UserContext';
import { TimePeriodProvider } from '../../context/TimePeriodContext';
import { SprintsProvider } from '../../context/SprintsContext';
import { MediaProvider } from '../../context/MediaContext';
import useParamsQuery from '../../hooks/useParamsQuery';
import useBreakpoint from '../../hooks/useBreakpoint';
import { RepoGroupsProvider } from '../../context/RepoGroupsContext';
import AuthPage from '../../pages/auth-page';
import InviteAuthPage from '../../pages/invite-auth-page';

export const ContentWrapper = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const query = useParamsQuery();
    const point = useBreakpoint();
    const orgHashFromUrl = query.get('org_hash');

    useEffect(() => {
        // const orgHashFromUrl = query.get('org_hash'); // First param
        const tokenFromUrl = query.get('token'); // Second param
        if (orgHashFromUrl) {
            localStorage.setItem('orgHash', orgHashFromUrl);
            localStorage.setItem('baseOrgHash', orgHashFromUrl);
            if (tokenFromUrl) {
                localStorage.setItem('token', tokenFromUrl);
            }
            navigate(location.pathname);
        }
        if (
            !window.localStorage.getItem('token') &&
            location.pathname.toLocaleLowerCase() !== '/login' &&
            location.pathname.toLocaleLowerCase() !== '/signup' &&
            location.pathname !== '/explore' &&
            location.pathname.toLocaleLowerCase() !== '/start-trial' &&
            location.pathname.toLocaleLowerCase() !== '/set-password' &&
            location.pathname.toLocaleLowerCase() !== '/reset-password' &&
            location.pathname.toLocaleLowerCase() !== '/invite'
        ) {
            if (location.pathname?.includes('/teams/')) {
                localStorage.setItem('startUrl', location.pathname);
            }
            navigate('/login');
        }
        if (
            location.pathname === '/start-trial' ||
            location.pathname === '/trial'
        ) {
            navigate('/signup');
        }
        if (location.pathname === '/demo') {
            navigate('/explore');
        }

        if (
            window.localStorage.getItem('token') &&
            (location.pathname.toLocaleLowerCase() === '/login' ||
                location.pathname.toLocaleLowerCase() === '/signup' ||
                location.pathname === '/explore' ||
                location.pathname === '/demo' ||
                location.pathname.toLocaleLowerCase() === '/start-trial' ||
                location.pathname.toLocaleLowerCase() === '/set-password' ||
                location.pathname.toLocaleLowerCase() === '/reset-password' ||
                location.pathname.toLocaleLowerCase() === '/invite')
        ) {
            navigate('/');
        }
    }, [location]);

    useEffect(() => {
        let pageTitle = window.location.pathname
            .replace('/', ' | ')
            .replace('-', ' ')
            .split(' ')
            .map((word) => {
                if (word === 'dora') {
                    return word.toUpperCase();
                }
                return word.length
                    ? word[0].toUpperCase() + word.substring(1)
                    : '';
            })
            .join(' ');

        if (window.location.pathname.includes('settings/')) {
            pageTitle = pageTitle.slice(0, 11);
        }

        document.title = `Agile Analytics ${pageTitle}`;
    });

    return (
        <Routes>
            <Route path="/login" exact element={<AuthPage type="login" />} />
            <Route path="/signup" exact element={<AuthPage type="signup" />} />
            <Route
                path="/explore"
                exact
                element={<AuthPage type="explore" />}
            />
            <Route path="/demo" exact element={<AuthPage type="demo" />} />
            <Route path="/invite" exact element={<InviteAuthPage />} />
            <Route path="/set-password" exact element={<SetPasswordPage />} />
            <Route path="/reset-password" exact element={<SetPasswordPage />} />
            <Route
                path="*"
                element={
                    <>
                        <TimePeriodProvider>
                            <MediaProvider value={point}>
                                <OrganisationsProvider>
                                    <UserProvider>
                                        <SprintsProvider>
                                            <RepoGroupsProvider>
                                                <Content
                                                    orgHashFromUrl={
                                                        orgHashFromUrl
                                                    }
                                                />
                                            </RepoGroupsProvider>
                                        </SprintsProvider>
                                    </UserProvider>
                                </OrganisationsProvider>
                            </MediaProvider>
                        </TimePeriodProvider>
                    </>
                }
            />
        </Routes>
    );
};
