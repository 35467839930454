import React, { useContext, useEffect, useState } from 'react';
import { useTour } from '@reactour/tour';
import { TimePeriodContext } from '../../../context/TimePeriodContext';
import ErrorIndicator from '../../../components/error-indicator';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import SyncChart from './sre-chart-sync';
import useParamsQuery from '../../../hooks/useParamsQuery';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '../../../helpers/textFormatters';
import _ from 'lodash';
import {
    ChartLoading,
    generateEventsChartOptionsBase,
    filterDataEvents,
    formatPlotLines,
    generateErrorBudgetChartOptionsBase,
    NoChartData,
} from '../../../helpers/sreChartHelpers';

const ErrorBudgetsChart = ({
    selectedService,
    serviceState,
    step,
    chartState,
    isInTeam,
    cardTimeperiod,
    deploymentsList,
}) => {
    const { t } = useTranslation();
    const [timePeriodState] = useContext(TimePeriodContext);
    const { date_start: dateTimeStart, date_end: dateTimeEnd } =
        timePeriodState;
    const [zoomTimeperiod, setZoomTimeperiod] = useState(null);
    const [dataFeaturesVisibility, setDataFeaturesVisibility] = useState(null);
    const [eventsVisibility, setEventsVisibility] = useState(null);

    const errorBudgetChartOptionsBase = generateErrorBudgetChartOptionsBase(
        dataFeaturesVisibility,
        setDataFeaturesVisibility,
        setZoomTimeperiod,
        isInTeam,
        step
    );

    const eventsChartOptionsBase = generateEventsChartOptionsBase(
        setZoomTimeperiod,
        eventsVisibility,
        setEventsVisibility,
        step
    );

    const { setIsOpen, currentStep, isOpen } = useTour();
    const query = useParamsQuery();

    const { organisationsState } = useContext(OrganisationsContext);
    const selectedOrgHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;
    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;

    const featuresList = serviceState?.data?.features?.map(
        (feature) => feature.slo_name
    );

    const [data, setData] = useState([{ data: [null, null] }]);
    const [dataEvents, setDataEvents] = useState([{ data: [null, null] }]);
    const [wasChartDataFormatted, setWasChartDataFormatted] = useState(false);
    const [wasStepOneDataFormatted, setWasStepOneDataFormatted] =
        useState(false);
    const [wasStepTwoDataFormatted, setWasStepTwoDataFormatted] =
        useState(false);
    const [deploymentsVisible, setDeploymentsVisible] = useState(true);

    const [zoomedDataEvents, setZoomedDataEvents] = useState(null);
    const [zoomedData, setZoomedData] = useState(null);
    const [eventsChartSeries, setEventsChartSeries] = useState(null);
    const [errorBudgetChartSeries, setErrorBudgetChartSeries] = useState(null);

    useEffect(() => {
        query.get('tour') === 'true' &&
            setTimeout(() => {
                setIsOpen(true);
            }, 3000);
        return () => setIsOpen(false);
    }, []);

    useEffect(() => {
        setWasChartDataFormatted(false);
    }, [
        dateTimeStart,
        dateTimeEnd,
        selectedService,
        selectedOrgHash,
        step,
        cardTimeperiod,
    ]);

    useEffect(() => {
        if (chartState?.data?.length) {
            let formattedData = data;
            if (demoOrgHash === selectedOrgHash && isOpen) {
                if (currentStep === 0) {
                    formattedData = [chartState.data[0]].map((feature) => {
                        feature.data &&
                            feature.data.map((item) => {
                                if (
                                    !wasChartDataFormatted ||
                                    !wasStepOneDataFormatted
                                ) {
                                    item[0] = item[0] * 1000;
                                }
                                return item;
                            });
                        return feature;
                    });
                    setWasStepOneDataFormatted(true);
                } else if (currentStep === 1) {
                    formattedData = [chartState.data[1]].map((feature) => {
                        feature.data &&
                            feature.data.map((item) => {
                                if (
                                    !wasChartDataFormatted ||
                                    !wasStepTwoDataFormatted
                                ) {
                                    item[0] = item[0] * 1000;
                                }
                                return item;
                            });
                        return feature;
                    });
                    setWasStepTwoDataFormatted(true);
                    wasStepOneDataFormatted && setWasChartDataFormatted(true);
                }
            } else {
                if (!wasChartDataFormatted) {
                    if (wasStepOneDataFormatted && !wasStepTwoDataFormatted) {
                        formattedData = _.cloneDeep(chartState.data).map(
                            (feature, index) => {
                                if (index !== 0) {
                                    feature.data &&
                                        feature.data.map((item) => {
                                            item[0] = item[0] * 1000;
                                            return item;
                                        });
                                }

                                return feature;
                            }
                        );
                        setWasStepTwoDataFormatted(true);
                        setWasChartDataFormatted(true);
                    } else if (
                        wasStepTwoDataFormatted &&
                        !wasStepOneDataFormatted
                    ) {
                        formattedData = chartState.data.map(
                            (feature, index) => {
                                if (index === 0) {
                                    feature.data &&
                                        feature.data.map((item) => {
                                            item[0] = item[0] * 1000;
                                            return item;
                                        });
                                }
                                return feature;
                            }
                        );
                        setWasStepOneDataFormatted(true);
                        setWasChartDataFormatted(true);
                    } else {
                        formattedData = chartState.data.map((feature) => {
                            feature.data &&
                                feature.data.map((item) => {
                                    item[0] = item[0] * 1000;
                                    return item;
                                });
                            return feature;
                        });
                    }
                    !isInTeam && setWasChartDataFormatted(true);
                } else {
                    formattedData = chartState.data.map((feature) => {
                        feature.data &&
                            feature.data.map((item) => {
                                return item;
                            });
                        return feature;
                    });
                    setWasChartDataFormatted(true);
                }
            }

            const formattedDataWithOptions = formattedData.map(
                (dataSeries, i) => {
                    let formattedSeries = {
                        ...dataSeries,
                        name: dataSeries.feature,
                        tooltip: {
                            valueSuffix: ' %',
                        },
                        visible: isInTeam && i !== 0 ? false : true,
                    };
                    return formattedSeries;
                }
            );
            setData(formattedDataWithOptions);

            if (!isInTeam) {
                let eventsData = formattedData.reduce(
                    (acc, seriesData, index) => {
                        let columnsBadSeries = {
                            title: seriesData.slo_name,
                            type: 'column',
                            data: [...seriesData.data].map((dataItem) => {
                                return [dataItem[0], dataItem[2].bad_events];
                            }),
                            name:
                                capitalizeFirstLetter(t('sre.bad')) +
                                ' ' +
                                capitalizeFirstLetter(t('sre.events')),
                            color: '#FF6384',
                            id: index.toString(),
                        };

                        let columnsGoodSeries = {
                            title: seriesData.slo_name,
                            type: 'column',
                            data: [...seriesData.data].map((dataItem) => {
                                return [dataItem[0], dataItem[2].good_events];
                            }),
                            name:
                                capitalizeFirstLetter(t('sre.good')) +
                                ' ' +
                                capitalizeFirstLetter(t('sre.events')),
                            color: '#8ec358',
                            visible: false,
                        };

                        acc = [...acc, columnsBadSeries, columnsGoodSeries];

                        return acc;
                    },
                    []
                );
                setDataEvents([...eventsData]);
            }
        }
    }, [chartState.data, currentStep, isOpen, serviceState]);

    useEffect(() => {
        const visibility = data.reduce((acc, dataSeries, i) => {
            return {
                ...acc,
                [dataSeries.slo_name ? dataSeries.slo_name : dataSeries.title]:
                    isInTeam && i !== 0 ? false : true,
            };
        }, {});

        setDataFeaturesVisibility(visibility);

        const updatedErrorBudgetOptions = {
            ...errorBudgetChartOptionsBase,
            series: [...data].map((series) => {
                return {
                    ...series,
                    visible: visibility
                        ? visibility[
                              series?.slo_name ? series?.slo_name : series?.name
                          ]
                        : true,
                };
            }),
            xAxis: {
                ...errorBudgetChartOptionsBase.xAxis,
                plotLines: null,
            },
        };
        setErrorBudgetChartSeries(updatedErrorBudgetOptions);
        setZoomTimeperiod(null);
    }, [data]);

    useEffect(() => {
        const visibility = dataEvents.reduce((acc, dataSeries, i) => {
            if (dataSeries?.id) {
                return {
                    ...acc,
                    [dataSeries.title]: {
                        [dataSeries?.name]: true,
                    },
                };
            } else {
                return {
                    ...acc,
                    [dataSeries.title]: {
                        ...acc[dataSeries?.title],
                        [dataSeries?.name]: false,
                    },
                };
            }
        }, {});

        setEventsVisibility(visibility);
        if (
            dataFeaturesVisibility &&
            Object.keys(dataFeaturesVisibility)?.length
        ) {
            const updatedEventsChartSeries = Object.keys(
                dataFeaturesVisibility
            ).reduce((acc, featureName) => {
                const updatedAcc = {
                    ...acc,
                    [featureName]: {
                        ...eventsChartOptionsBase,
                        series: [...dataEvents]
                            .filter(
                                (dataSeries) => dataSeries.title === featureName
                            )
                            ?.map((series) => {
                                const updated = {
                                    ...series,
                                    visible: series?.id ? true : false,
                                };
                                return updated;
                            }),
                    },
                };

                return updatedAcc;
            }, {});

            setEventsChartSeries(updatedEventsChartSeries);
        }
    }, [dataEvents]);

    useEffect(() => {
        if (
            dataFeaturesVisibility &&
            Object.keys(dataFeaturesVisibility)?.length
        ) {
            const updatedEventsChartSeries = Object.keys(
                dataFeaturesVisibility
            ).reduce((acc, featureName) => {
                const updatedAcc = {
                    ...acc,
                    [featureName]: {
                        ...eventsChartOptionsBase,
                        series: zoomTimeperiod
                            ? [...zoomedDataEvents]
                                  .filter(
                                      (dataSeries) =>
                                          dataSeries.title === featureName
                                  )
                                  ?.map((series) => {
                                      const updated = {
                                          ...series,
                                          visible:
                                              eventsVisibility[featureName][
                                                  series?.name
                                              ],
                                      };
                                      return updated;
                                  })
                            : [...dataEvents]
                                  .filter(
                                      (dataSeries) =>
                                          dataSeries.title === featureName
                                  )
                                  ?.map((series) => {
                                      const updated = {
                                          ...series,
                                          visible:
                                              eventsVisibility[featureName][
                                                  series?.name
                                              ],
                                      };
                                      return updated;
                                  }),
                    },
                };

                return updatedAcc;
            }, {});

            setEventsChartSeries(updatedEventsChartSeries);
        }
    }, [zoomedDataEvents, eventsVisibility]);

    useEffect(() => {
        if (
            dataFeaturesVisibility &&
            Object.keys(dataFeaturesVisibility)?.length
        ) {
            // update events data
            const copiedDataEvents = _.cloneDeep(dataEvents);
            const updatedEventsOptions = Object.keys(
                dataFeaturesVisibility
            ).reduce((acc, featureName) => {
                const updatedAcc = {
                    ...acc,
                    [featureName]: {
                        ...eventsChartOptionsBase,
                        series: zoomTimeperiod
                            ? [...zoomedDataEvents]
                                  ?.filter(
                                      (dataSeries) =>
                                          dataSeries.title === featureName
                                  )
                                  ?.map((series) => {
                                      const updated = {
                                          ...series,
                                          visible:
                                              eventsVisibility[featureName][
                                                  series?.name
                                              ],
                                      };
                                      return updated;
                                  })
                            : [...copiedDataEvents]
                                  .filter(
                                      (dataSeries) =>
                                          dataSeries.title === featureName
                                  )
                                  ?.map((series) => {
                                      const updated = {
                                          ...series,
                                          visible:
                                              eventsVisibility[featureName][
                                                  series?.name
                                              ],
                                      };
                                      return updated;
                                  }),
                    },
                };
                return updatedAcc;
            }, {});
            setEventsChartSeries(updatedEventsOptions);

            // update error budgets chart data
            const copiedData = _.cloneDeep(data);
            const updatedErrorBudgetOptions = {
                ...errorBudgetChartOptionsBase,
                series: zoomTimeperiod
                    ? [...zoomedData].map((series) => {
                          return {
                              ...series,
                              visible: dataFeaturesVisibility
                                  ? dataFeaturesVisibility[
                                        series?.slo_name
                                            ? series?.slo_name
                                            : series?.name
                                    ]
                                  : true,
                          };
                      })
                    : [...copiedData].map((series) => {
                          return {
                              ...series,
                              visible: dataFeaturesVisibility
                                  ? dataFeaturesVisibility[
                                        series?.slo_name
                                            ? series?.slo_name
                                            : series?.name
                                    ]
                                  : true,
                          };
                      }),
                xAxis: {
                    ...errorBudgetChartOptionsBase.xAxis,
                    plotLines: null,
                },
            };
            setErrorBudgetChartSeries(updatedErrorBudgetOptions);
        }
    }, [zoomedDataEvents, zoomedData, dataFeaturesVisibility]);

    useEffect(() => {
        const updatedErrorBudgetOptions = {
            ...errorBudgetChartOptionsBase,
            series: errorBudgetChartSeries?.series?.map((series) => {
                return {
                    ...series,
                    visible: dataFeaturesVisibility
                        ? dataFeaturesVisibility[
                              series?.slo_name ? series?.slo_name : series?.name
                          ]
                        : true,
                };
            }),
            xAxis: {
                ...errorBudgetChartOptionsBase.xAxis,
                plotLines: null,
            },
        };

        setErrorBudgetChartSeries(updatedErrorBudgetOptions);
    }, [deploymentsVisible]);

    useEffect(() => {
        const copiedDataEvents = _.cloneDeep(dataEvents);
        const copiedData = _.cloneDeep(data);
        if (!zoomTimeperiod) {
            setZoomedDataEvents([...copiedDataEvents]);
            setZoomedData([...copiedData]);
        } else {
            if (copiedDataEvents) {
                const filteredEvents = filterDataEvents(
                    [...copiedDataEvents],
                    zoomTimeperiod[0],
                    zoomTimeperiod[1]
                );

                setZoomedDataEvents(filteredEvents);
            }
            if (copiedData) {
                const filteredData = filterDataEvents(
                    [...copiedData],
                    zoomTimeperiod[0],
                    zoomTimeperiod[1]
                );
                setZoomedData([...filteredData]);
            }
        }
    }, [zoomTimeperiod]);

    if (!featuresList?.length && !isInTeam) {
        return (
            <div className="mr-5" style={{ width: '100%', height: '190px' }}>
                <p className="text-center text-gray-500 text-xl p-4">
                    No SLOs. Add a SLO to start gathering data
                </p>
            </div>
        );
    }

    if (chartState.loading) {
        return <ChartLoading />;
    }

    if (chartState.error) {
        return (
            <div className="mr-5" style={{ width: '100%', height: '190px' }}>
                <ErrorIndicator error={chartState.error} />
            </div>
        );
    }

    if (chartState.data && chartState.data?.length === 0) {
        return <NoChartData />;
    }

    const clonedOptions = JSON.parse(JSON.stringify(errorBudgetChartSeries));
    if (errorBudgetChartSeries?.chart?.events) {
        clonedOptions.chart.events = errorBudgetChartSeries.chart.events;
        clonedOptions.tooltip.formatter =
            errorBudgetChartSeries.tooltip.formatter;
        clonedOptions.tooltip.positioner =
            errorBudgetChartSeries.tooltip.positioner;
        clonedOptions.plotOptions = errorBudgetChartSeries.plotOptions;
        clonedOptions.legend = errorBudgetChartSeries.legend;
        clonedOptions.legend.labelFormatter =
            errorBudgetChartSeries.legend.labelFormatter;
        clonedOptions.xAxis.plotLines = deploymentsVisible
            ? zoomTimeperiod
                ? formatPlotLines(
                      deploymentsList,
                      zoomTimeperiod[0],
                      zoomTimeperiod[1]
                  )
                : formatPlotLines(
                      deploymentsList,
                      dateTimeStart * 1000,
                      dateTimeEnd * 1000
                  )
            : null;
    }

    return (
        <div
            data-tour="r-step-page-4"
            data-tour-2="r-step-page-5"
            className="relative"
        >
            <div className="absolute right-2 -top-8 flex gap-4 items-center">
                <button
                    type="button"
                    className="btn-text-blue"
                    onClick={() => {
                        setDeploymentsVisible((prevState) => !prevState);
                    }}
                >
                    {deploymentsVisible ? 'Hide' : 'Show'} deployments
                </button>
                {zoomTimeperiod ? (
                    <button
                        type="button"
                        className=" btn-white-hover-blue"
                        onClick={() => {
                            setZoomTimeperiod(null);
                        }}
                    >
                        Reset zoom
                    </button>
                ) : null}
            </div>

            {clonedOptions ? <SyncChart options={clonedOptions} /> : null}

            {isInTeam ? null : (
                <div className="mt-4">
                    {dataFeaturesVisibility &&
                        Object.keys(dataFeaturesVisibility).map(
                            (featureName) => {
                                if (dataFeaturesVisibility[featureName]) {
                                    return (
                                        <div key={featureName}>
                                            <div className="flex justify-between items-center">
                                                <p className="mb-4 font-display text-xl font-light ">
                                                    {featureName} good/bad
                                                    events
                                                </p>
                                                {zoomTimeperiod ? (
                                                    <button
                                                        type="button"
                                                        className="btn-white-hover-blue"
                                                        onClick={() => {
                                                            setZoomTimeperiod(
                                                                null
                                                            );
                                                        }}
                                                    >
                                                        Reset zoom
                                                    </button>
                                                ) : null}
                                            </div>
                                            {eventsChartSeries &&
                                            eventsChartSeries[featureName] ? (
                                                <SyncChart
                                                    options={
                                                        eventsChartSeries[
                                                            featureName
                                                        ]
                                                    }
                                                />
                                            ) : null}
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            }
                        )}
                </div>
            )}
        </div>
    );
};

export default ErrorBudgetsChart;
