import React, { useCallback } from 'react';
import moment from 'moment';
import {
    IoCheckmarkCircleOutline,
    IoCloseCircleOutline,
} from 'react-icons/io5';
import { useFetchDataAndSetState } from '../../../helpers/useFetchDataAndSetState';
import { getErrorBudgets } from '../../../api/settings-slo/slo';
import Loader from '../../../components/loader';
import { useTranslation } from 'react-i18next';

function SloCard({ chartData, sloData, service, step, showService }) {
    const { t } = useTranslation();

    const historyRequest = useCallback(async () => {
        let response = { data: null, status: 204 };

        const dates = [];
        while (dates?.length < 4) {
            let week = null;
            let year = null;
            if (!dates?.length) {
                // calculate endTimestamp and startTimestamp for the current week (endTimestamp mught be in the future)
                week = moment().isoWeek();
                year = moment().year();
            } else {
                const endMomentObj = moment(
                    (dates[dates?.length - 1][0] - 1) * 1000
                );
                week = endMomentObj.isoWeek();
                year = endMomentObj.year();
            }

            // Get the start of the week (Monday)
            const weekStart = moment()
                .year(year)
                .week(week)
                .startOf('week')
                .unix();

            // Get the end of the week (Sunday)
            const weekEnd = moment().year(year).week(week).endOf('week').unix();

            // console.log(weekStart, weekEnd);
            dates.push([weekStart, weekEnd]);
        }

        try {
            const values = await Promise.allSettled(
                dates.map((date) =>
                    getErrorBudgets(
                        service,
                        date[0],
                        date[1],
                        sloData?.feature_name,
                        step?.value
                    )
                )
            );

            if (values.length) {
                if (!values.find((value) => value.status === 'fulfilled')) {
                    response = {
                        ...response,
                        status: 404,
                        error: values[0].reason,
                    };
                } else {
                    let status = 200;

                    if (
                        !values.find(
                            (value) =>
                                value.status === 'fulfilled' &&
                                value.value.status !== 204
                        )
                    ) {
                        status = 204;
                    }

                    const filteredValues = values
                        .filter((value) => value.status === 'fulfilled')
                        .map((value) =>
                            value.value.data ? value.value.data : undefined
                        )
                        .filter((value) => value !== undefined);

                    const calculatedSloData = filteredValues.map((item) => {
                        return calculateAverageSloValue(
                            item?.data,
                            sloData?.goal
                        );
                    });

                    response = {
                        ...response,
                        data: {
                            slo: calculatedSloData,
                            dates,
                        },
                        status: status,
                    };
                }
            }
        } catch (err) {
            console.log(err);
        }

        return response;
    }, []);

    const [historyState] = useFetchDataAndSetState(historyRequest, []);

    const filteredData = filterData(chartData?.data);

    function filterData(dataArray) {
        if (!dataArray?.length) {
            return null;
        }
        // return only error budget value
        return dataArray?.map((item) => item[1]);
    }

    // simplified SLO average calc
    function calculateAverageSloValue(dataPoints, target) {
        const slosForEachPoint = dataPoints.map((dataPoint) => {
            const slo = calculateSloValue([dataPoint[1]], target);
            return +slo;
        });

        const averageSlo =
            slosForEachPoint?.reduce((acc, item) => +acc + +item, [0]) /
            slosForEachPoint?.length;

        return averageSlo.toFixed(3);
    }

    function calculateSloValue(filteredData, target) {
        // calculating SLO from error budget value: value as SLO target +/- remaining error budget
        if (!filteredData?.length || !target) {
            return 0;
        }

        const latestMeasurement = filteredData[filteredData.length - 1];
        const totalErrorBudget = 1 - target;
        const avaivableErrorBudget =
            (totalErrorBudget * latestMeasurement) / 100;

        const slo = (target + avaivableErrorBudget) * 100;
        return slo.toFixed(3);
    }

    return (
        <div className="relative rounded border border-solid border-gray-200 flex flex-col ">
            <p className="border-b border-solid border-gray-200 p-4 text-center font-display text-xl">
                {sloData?.slo_name}
                <br />
                <span className="text-sm">
                    {t('sre.in_the_last_time_step')} {step.value}
                </span>
            </p>
            {showService ? (
                <p className="absolute px-1 py-px font-display text-xs text-gray-400 font-light">
                    Service: {service}
                </p>
            ) : null}
            <div className="p-4 flex-grow">
                <p className="text-center font-display  font-semibold text-xl ">
                    {calculateSloValue(filteredData, sloData?.goal)
                        ? calculateSloValue(filteredData, sloData?.goal) + '%'
                        : 'no SLO DATA'}
                </p>

                <div className="flex justify-center gap-1 mt-4 items-center">
                    {calculateSloValue(filteredData, sloData?.goal) >
                    sloData?.goal * 100 ? (
                        <IoCheckmarkCircleOutline size={24} color={'#8ec358'} />
                    ) : (
                        <IoCloseCircleOutline size={24} color={'#C03A3A'} />
                    )}
                    <p className=" ">
                        Target:{' '}
                        {sloData?.goal
                            ? `${sloData?.goal * 100}%`
                            : 'no target set'}
                    </p>
                </div>
            </div>
            <div className="border-t border-solid border-gray-200 p-4">
                <p className="mb-2 text-sm">Last 4-week history:</p>
                {historyState?.loading ? (
                    <div className="flex justify-center items-center">
                        <Loader size={16} />
                    </div>
                ) : historyState?.data?.slo?.length ? (
                    <ul className="grid grid-cols-4 gap-2">
                        {historyState?.data?.slo?.map((item, i) => (
                            <li
                                after-dynamic-value={`Week ${moment(
                                    historyState?.data?.dates[i][0] * 1000
                                ).isoWeek()}: ${moment
                                    .unix(historyState?.data?.dates[i][0])
                                    .format('D MMM')} - ${moment
                                    .unix(historyState?.data?.dates[i][1])
                                    .format('D MMM')}`}
                                key={item.toString() + i}
                                className={`p-1 text-white text-xs text-center relative ${
                                    item > sloData.goal * 100
                                        ? 'bg-green-500'
                                        : 'bg-red-text'
                                } after:content-[attr(after-dynamic-value)] after:opacity-0 hover:after:opacity-100 after:py-1 after:px-1 after:basic-transition after:absolute after:bg-white after:inline-block after:text-xs after:rounded after:border after:border-solid after:border-gray-200  after:text-gray-500 after:mb-4 after:z-50 after:min-w-[180px] ${
                                    i > 0 ? 'after:right-0' : 'after:left-0'
                                } after:top-7 after:z-50`}
                            >
                                {+item === 100 ? 100 : item}%
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="text-xs text-gray-500 text-center">
                        No data
                    </div>
                )}
            </div>
        </div>
    );
}

export default SloCard;
