import React, { useCallback, useContext, useState, useEffect } from 'react';
import moment from 'moment';
import ErrorBudgetsChart from './sre-chart-error-budgets';
import StubChart from './sre-chart-stub';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { OrganisationsContext } from '../../../context/OrganisationsContext';
import { capitalizeEveryWordFirstLetter } from '../../../helpers/textFormatters';
import { useFetchDataAndSetState } from '../../../helpers/useFetchDataAndSetState';
import {
    getErrorBudgets,
    getServiceByName,
} from '../../../api/settings-slo/slo';
import { TimePeriodContext } from '../../../context/TimePeriodContext';
import SloCard from './sre-slo-card';
import ServiceEditForm from './sre-service-edit-form';
import showNotification from '../../../helpers/showNotification';
import { UserContext } from '../../../context/UserContext';
import { useRef } from 'react';
import useOnClickOutside from '../../../helpers/closeOnOutsideClick';
import SreNote from './sre-note';
import { Flags } from 'react-feature-flags';

function SREService({
    service,
    services,
    servicesDispatch,
    setIsEditing,
    sortedDeploymentFreqData,
}) {
    const { userState } = useContext(UserContext);
    const { t } = useTranslation();
    const ref = useRef();
    const { organisationsState } = useContext(OrganisationsContext);
    const selectedOrgHash = organisationsState.data
        ? organisationsState.data.find((org) => org.active).org_hash
        : null;

    const demoOrgHash = process.env.REACT_APP_DEMO_ORG_HASH;

    const [timePeriodState] = useContext(TimePeriodContext);
    const { date_start: dateTimeStart, date_end: dateTimeEnd } =
        timePeriodState;

    const [serviceStatus, setServiceStatus] = useState({
        modalState: {
            manage: false,
        },
        service: {
            value: service?.service ? service?.service : 'default',
            label: t('sre.select_service'),
        },
    });

    const serviceRequest = useCallback(() => {
        if (
            serviceStatus.service.value &&
            !['default', 'handlerAddService', 'handlerManageService'].includes(
                serviceStatus.service.value
            )
        ) {
            return getServiceByName(serviceStatus.service.value);
        }

        return Promise.resolve({ status: 204 });
    }, [serviceStatus.service.value]);

    const [serviceState, serviceDispatch] = useFetchDataAndSetState(
        serviceRequest,
        [service]
    );

    const [step, setStep] = useState({
        value: '24 hours',
        label: `24 ${t('sre.hours_step')}`,
    });

    const featuresList = serviceState?.data?.features?.map(
        (feature) => feature.feature_name
    );

    const request = useCallback(async () => {
        let response = { data: null, status: 200 };

        if (featuresList?.length) {
            try {
                const values = await Promise.allSettled(
                    featuresList.map((feature) =>
                        getErrorBudgets(
                            serviceState?.data?.service,
                            dateTimeStart,
                            dateTimeEnd,
                            feature,
                            step?.value
                        )
                    )
                );

                if (values.length) {
                    if (!values.find((value) => value.status === 'fulfilled')) {
                        response = {
                            ...response,
                            status: 404,
                            error: values[0].reason,
                        };
                    } else {
                        let status = 200;

                        if (
                            !values.find(
                                (value) =>
                                    value.status === 'fulfilled' &&
                                    value.value.status !== 204
                            )
                        ) {
                            status = 204;
                        }

                        response = {
                            ...response,
                            data: values
                                .filter((value) => value.status === 'fulfilled')
                                .map((value) =>
                                    value.value.data
                                        ? value.value.data
                                        : undefined
                                )
                                .filter((value) => value !== undefined),
                            status: 200,
                        };
                    }
                }
            } catch (err) {
                console.log(err);
            }
        } else {
            response = { data: [], status: 204 };
        }

        return response;
    }, [dateTimeStart, dateTimeEnd, serviceState.data, step]);

    const [chartState] = useFetchDataAndSetState(request, [
        dateTimeStart,
        dateTimeEnd,
    ]);

    const [isEdit, setIsEdit] = useState(false);
    const [isAddingNote, setIsAddingNote] = useState(false);
    const [newNote, setNewNote] = useState('');

    const stepOptions = [
        { value: '1 hours', label: `1 ${t('sre.hours_step')}` },
        { value: '12 hours', label: `12  ${t('sre.hours_step')}` },
        { value: '24 hours', label: `24  ${t('sre.hours_step')}` },
        { value: '48 hours', label: `48  ${t('sre.hours_step')}` },
    ];

    useEffect(() => {
        setServiceStatus({
            modalState: {
                manage: false,
            },
            service: {
                value: service?.service ? service?.service : 'default',
                label: t('sre.select_service'),
            },
        });
    }, [service]);

    useOnClickOutside(ref, () => setIsAddingNote(false), []);

    async function handleAddingNote() {
        if (!newNote?.length) {
            showNotification(`the note is empty`);
            return;
        }

        let response = { data: null, status: 200 };
        try {
            // response = await addNote(newNote); TODO: API request
            if (response?.status === 200) {
                const timestamp = moment().unix();
                const user =
                    userState?.data?.first_name || userState?.data?.last_name
                        ? `${
                              userState?.data?.first_name
                                  ? userState?.data?.first_name
                                  : ''
                          } ${
                              userState?.data?.last_name
                                  ? userState?.data?.last_name
                                  : ''
                          }`
                        : userState?.data?.email ?? 'unknown user';
                const updatedService = {
                    ...serviceState.data,
                    notes: serviceState?.data?.notes?.length
                        ? [
                              ...serviceState?.data?.notes,
                              { text: newNote, timestamp, user },
                          ]
                        : [{ text: newNote, timestamp, user }],
                };
                serviceDispatch({ type: 'SET_DATA', payload: updatedService });
            }
        } catch (err) {
            console.log(err);
            showNotification(
                err.response?.data ||
                    `Note has not been added, please try again`
            );
        }
        setNewNote('');
        setIsAddingNote(false);
    }

    async function handleEditingNote(updatedNote, oldTimestamp) {
        let response = { data: null, status: 200 };
        try {
            // response = await editNote(updatedNote); TODO: API request
            if (response?.status === 200) {
                const updatedService = {
                    ...serviceState.data,
                    notes: serviceState?.data?.notes?.map((note) => {
                        if (note.timestamp === oldTimestamp) {
                            return updatedNote;
                        }
                        return note;
                    }),
                };
                serviceDispatch({ type: 'SET_DATA', payload: updatedService });
            }
        } catch (err) {
            console.log(err);
            showNotification(
                err.response?.data ||
                    `Note has not been updated, please try again`
            );
        }
        setNewNote('');
        setIsAddingNote(false);
    }

    async function handlDeleteNote(timestamp) {
        let response = { data: null, status: 200 };
        try {
            // response = await deleteNote(timestamp); TODO: API request
            if (response?.status === 200) {
                const updatedService = {
                    ...serviceState.data,
                    notes: serviceState?.data?.notes?.reduce((acc, note) => {
                        if (note.timestamp === timestamp) {
                            return acc;
                        }
                        return [...acc, note];
                    }, []),
                };
                serviceDispatch({ type: 'SET_DATA', payload: updatedService });
            }
        } catch (err) {
            console.log(err);
            showNotification(
                err.response?.data ||
                    `Note has not been updated, please try again`
            );
        }
        setNewNote('');
        setIsAddingNote(false);
    }

    return (
        <div className="flex mb-10 bg-white px-9 py-4 rounded-lg shadow-md">
            <div className="w-full">
                <div className="">
                    {isEdit ? (
                        <ServiceEditForm
                            service={service}
                            serviceState={serviceState}
                            serviceDispatch={serviceDispatch}
                            services={services}
                            servicesDispatch={servicesDispatch}
                            onGoBack={() => {
                                setIsEdit(false);
                                setIsEditing(false);
                            }}
                        />
                    ) : (
                        <div className="flex justify-between mb-6 items-start">
                            <div className="fle flex-col">
                                <h1 className="font-display text-28 text-gray-700 font-light border-b-2 border-solid border-theme-primary">
                                    {capitalizeEveryWordFirstLetter(
                                        service?.service
                                            ? service?.service
                                            : 'No name'
                                    )}
                                </h1>
                                {service?.description ? (
                                    <p className="mt-4 text-gray-500 ">
                                        {service?.description}
                                    </p>
                                ) : null}
                                {service?.url ? (
                                    <div className="flex mt-2 gap-1 items-center">
                                        <p className="text-xs text-gray-500 font-light">
                                            Operational dashboard URL:{' '}
                                        </p>
                                        <a
                                            className="block text-xs text-theme-tertiary underline "
                                            target="_blank"
                                            href={service?.url}
                                        >
                                            {service?.url}
                                        </a>
                                    </div>
                                ) : null}
                            </div>
                            <button
                                type="button"
                                className="btn-white-hover-blue"
                                onClick={() => {
                                    setIsEdit(true);
                                    setIsEditing(true);
                                }}
                            >
                                {/* <EditIcon className="fill-current text-gray-400 hover:text-gray-500" /> */}
                                Edit
                            </button>
                        </div>
                    )}
                </div>

                <div className={`${isEdit ? 'hidden' : null} h-fit`}>
                    {serviceState?.data?.features?.length &&
                    !chartState?.loading ? (
                        <>
                            <div className="flex items-center justify-between ">
                                <p className="mb-1 font-display text-2xl font-light ">
                                    SLOs:
                                </p>
                                {selectedOrgHash === demoOrgHash ? (
                                    ''
                                ) : (
                                    <div className="w-48 mb-2">
                                        <Select
                                            isSearchable={false}
                                            options={stepOptions}
                                            value={step}
                                            onChange={(option) => {
                                                setStep(option);
                                            }}
                                            styles={setStepsSelectStyle()}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="w-full grid gap-4 grid-cols-4 mb-8 items-stretch">
                                {serviceState?.data?.features.map((slo) => (
                                    <SloCard
                                        key={slo.slo_name}
                                        sloData={serviceState?.data?.features.find(
                                            (feature) => {
                                                return (
                                                    feature.feature_name ===
                                                    slo.feature_name
                                                );
                                            }
                                        )}
                                        chartData={chartState?.data?.find(
                                            (chart) => {
                                                return (
                                                    chart.feature ===
                                                    slo.feature_name
                                                );
                                            }
                                        )}
                                        service={service?.service}
                                        step={step}
                                    />
                                ))}
                            </div>
                        </>
                    ) : null}

                    {!chartState?.loading ? (
                        <div className="flex items-center justify-between ">
                            <p className="my-2 font-display text-2xl font-light ">
                                Error Budget:
                            </p>
                        </div>
                    ) : null}

                    {[
                        'default',
                        'handlerAddService',
                        'handlerManageService',
                    ].includes(serviceStatus.service.value) ||
                    !serviceStatus.service.value ? (
                        <StubChart />
                    ) : (
                        <ErrorBudgetsChart
                            selectedService={serviceStatus?.service?.value}
                            services={services?.data}
                            serviceState={serviceState}
                            chartState={chartState}
                            step={step.value}
                            deploymentsList={sortedDeploymentFreqData}
                        />
                    )}

                    <Flags authorizedFlags={['sloNotesFeature']}>
                        <div className="text-gray-500 mt-4">
                            <p className="mb-2">Notes:</p>
                            {serviceState?.data?.notes ? (
                                <div className="flex flex-col gap-2">
                                    {serviceState?.data?.notes.map(
                                        (note, i) => (
                                            <SreNote
                                                i={i}
                                                key={note.timestamp}
                                                note={note}
                                                notesLength={
                                                    serviceState?.data?.notes
                                                        ?.length
                                                }
                                                onNoteEdit={handleEditingNote}
                                                onNoteDelete={handlDeleteNote}
                                            />
                                        )
                                    )}
                                </div>
                            ) : null}
                            {isAddingNote ? (
                                <div
                                    type="button"
                                    className="w-full p-2 rounded border border-solid border-gray-500 flex gap-x-4"
                                    ref={ref}
                                >
                                    <input
                                        id={serviceStatus.service.value}
                                        value={newNote}
                                        type="text"
                                        placeholder="Your note..."
                                        className="w-full outline-none"
                                        autoFocus={true}
                                        onBlur={({ target }) =>
                                            isAddingNote && target.focus()
                                        }
                                        onChange={(event) => {
                                            setNewNote(event.target.value);
                                        }}
                                        onKeyDown={(event) =>
                                            event.key.toLocaleLowerCase() ===
                                                'enter' && handleAddingNote()
                                        }
                                    />
                                    <button onClick={() => handleAddingNote()}>
                                        Save
                                    </button>
                                </div>
                            ) : (
                                <button
                                    type="button"
                                    className="w-full mt-2 p-2 rounded border border-solid border-gray-500"
                                    onClick={() => setIsAddingNote(true)}
                                >
                                    {serviceState?.data?.notes?.length
                                        ? 'Add another note'
                                        : 'No notes found. Click to add some'}
                                </button>
                            )}
                        </div>
                    </Flags>
                </div>
            </div>
        </div>
    );
}

export default SREService;

const setStepsSelectStyle = () => ({
    control: (provided) => ({
        ...provided,
        outline: 'none',
        boxShadow: 'none',
        backgroundColor: 'transparent',
        opacity: '1',
        '&:hover': {
            opacity: '1',
        },
    }),
    singleValue: (provided) => ({
        ...provided,
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        opacity: '1',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        opacity: '1',
        cursor: 'pointer',
        '&:hover': {
            color: '#dbdee9',
        },
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        opacity: '1',
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: '0',
        padding: '0',
    }),
    valueContainer: (provided) => ({
        ...provided,
        flexWrap: 'no-wrap',
    }),
    option: (provided, state) => ({
        ...provided,
        cursor: 'pointer',
        color: '#484a53',
        backgroundColor: 'transparent',
        '&:hover': {
            backgroundColor: state.isSelected
                ? '#4C72BD'
                : 'rgba(76, 114, 189, 0.5)',
        },
    }),
});
