import i18n from '../../i18n';
import AuthSocialButton from '../../components/auth-social-button';
import SSOAuthGoogle from './google-sso';
import showNotification from '../showNotification';
import { getSaml } from '../../api/auth/AuthAPI';
import AuthPasswordFormFields from '../../components/auth-password-form-fields';
import AuthPasswordForm from '../../components/auth-password-form';
import { Flags } from 'react-feature-flags';

export default function renderLoginOptions(
    endpoint,
    option,
    isSubmitting,
    errors,
    values,
    setErrorMessage,
    onPasswordSubmitFunc,
    disabled
) {
    const t = i18n.t;

    function ssoLogin() {
        getSaml()
            .then((response) => {
                const { authUri } = response.data;
                window.location.href = authUri;
            })
            .catch((error) => {
                showNotification(error.response?.data || error.message);
            });
    }

    switch (option) {
        case 'facebook':
        case 'microsoft':
        case 'linkedin':
        case 'google':
            return (
                <AuthSocialButton
                    setErrorMessage={setErrorMessage}
                    social={option}
                    endpoint={endpoint}
                    disabled={disabled}
                />
            );
        case 'saml':
            ssoLogin();
            return <SSOAuthGoogle setErrorMessage={setErrorMessage} />;
        case 'password':
            if (endpoint === 'login') {
                return (
                    <>
                        <AuthPasswordFormFields
                            endpoint={endpoint}
                            values={values}
                            errors={errors}
                            isSubmitting={isSubmitting}
                        />
                        <p className=" w-full text-center text-gray-400 text-sm uppercase mt-4 ">
                            {t('login_page.or')}
                        </p>{' '}
                    </>
                );
            } else if (
                endpoint === 'invite' ||
                endpoint === 'demo' ||
                endpoint === 'explore'
            ) {
                if (disabled) {
                    return;
                }
                return (
                    <>
                        <AuthPasswordForm
                            endpoint={endpoint}
                            onSubmitFunc={onPasswordSubmitFunc}
                        />
                        <p className=" w-full text-center text-gray-400 text-sm uppercase mt-4 ">
                            {t('login_page.or')}
                        </p>{' '}
                    </>
                );
            } else {
                return (
                    <Flags authorizedFlags={['passwordSignupFeature']}>
                        <AuthPasswordForm
                            endpoint={endpoint}
                            onSubmitFunc={onPasswordSubmitFunc}
                        />
                        <p className=" w-full text-center text-gray-400 text-sm uppercase mt-4 ">
                            {t('login_page.or')}
                        </p>{' '}
                    </Flags>
                );
            }
        case 'all':
        default:
            return (
                <>
                    {renderLoginOptions(
                        endpoint,
                        'password',
                        isSubmitting,
                        errors,
                        values,
                        setErrorMessage,
                        onPasswordSubmitFunc,
                        disabled
                    )}
                    {/* <p className=" w-full text-center text-gray-400 text-sm uppercase mt-4 ">
                        {t('login_page.or')}
                    </p>{' '} */}
                    <AuthSocialButton
                        setErrorMessage={setErrorMessage}
                        social={'google'}
                        endpoint={endpoint}
                        disabled={disabled}
                    />
                    <AuthSocialButton
                        setErrorMessage={setErrorMessage}
                        social={'linkedin'}
                        endpoint={endpoint}
                        disabled={disabled}
                    />
                    <AuthSocialButton
                        setErrorMessage={setErrorMessage}
                        social={'microsoft'}
                        endpoint={endpoint}
                        disabled={disabled}
                    />
                </>
            );
    }
}
